:root {
  --background-color: #111;
  --font-color: #eee;
  --primary-color: #FFA500;
  --secondary-color: #4FB1CC;
  background-color: var(--background-color);
  color: var(--font-color);
}

html, body, #root {
  margin: 0;
  height: 100%;
  font-family: 'Roboto Mono', monospace;
}
.page {
  display: flex;
  flex-direction: column; 
  align-items: center;
  background-color: var(--background-color);
  color: whitesmoke;
  height: 100vh;
}

.title {
  font-size: 5em;
}

.link {
  color: #61DAFB;
}